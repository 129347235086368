<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradienteee" x1="0.666" x2="0.422" y2="1">
     <stop offset="0" stop-color="#ffc117"/>
     <stop offset="1" stop-color="#e64700"/>
    </linearGradient>
   </defs>
   <g id="Soundcloud" transform="translate(-370 -448)">
    <rect :fill="background" data-name="Rectangle 21" height="60" id="Rectangle_21" transform="translate(370 448)" width="60"/>
    <path d="M-2.641,23.042a12.167,12.167,0,0,1,.114-1.431q.114-.91.309-1.008.2.1.342,1.057a10.439,10.439,0,0,1,.146,1.382,9.752,9.752,0,0,1-.146,1.381c-.1.617-.211.96-.342,1.025Q-2.641,25.22-2.641,23.042Zm2.082,0q0-.813.163-2.408t.2-1.983a.172.172,0,0,1,.2-.2q.13,0,.2.2.39,4.194.39,4.391A21.729,21.729,0,0,1,.4,25.35Q.22,26.879.188,27.27c-.044.15-.109.227-.2.227-.13,0-.2-.075-.2-.227q-.032-.358-.2-1.9T-.56,23.042Zm2.635,0q0-.782.163-2.392T2.4,18.877c0-.2.076-.293.228-.293.173,0,.26.1.26.293q.358,3.676.358,4.164t-.358,4.424a.23.23,0,0,1-.26.26c-.151,0-.228-.087-.228-.26q0-.227-.163-1.9T2.075,23.042Zm2.992,0q0-1.237.049-2.618t.13-3.091q.082-1.708.114-2.651,0-.325.293-.325a.287.287,0,0,1,.325.325q.032.52.179,3.627T6.3,23.041q0,.846-.163,2.5t-.163,1.887a.287.287,0,0,1-.325.325c-.2,0-.293-.108-.293-.325Q5.069,23.139,5.067,23.042Zm3.057,0q0-1.79.1-5.188t.13-4.18q.066-.39.39-.39t.39.39q.032,1.041.146,4.325t.114,5.043q0,.813-.114,2.342t-.146,1.984q-.064.39-.39.39c-.218,0-.347-.13-.39-.39q0-.357-.114-1.92T8.125,23.042Zm3.09,0q0-1.3.033-2.766t.081-3.253q.049-1.788.081-2.765c.043-.325.2-.488.455-.488s.412.163.455.488q.032.976.081,2.765t.081,3.253q.032,1.464.033,2.766,0,.064-.2,4.26V27.27q-.066.486-.455.487T11.41,27.3Q11.215,23.106,11.215,23.042Zm3.09.032q0-.1.2-11.417c.044-.39.217-.585.52-.585s.477.2.52.585q.2,11.319.2,11.417,0,.032-.2,4.131c-.044.368-.217.553-.52.553s-.477-.185-.52-.553Q14.3,23.106,14.3,23.073Zm2.6-6.912q0-2.683.032-5.058-.032-.325.407-.585a3.2,3.2,0,0,1,1.057-.374q.617-.114,1.122-.179a6.516,6.516,0,0,1,.8-.065,7.5,7.5,0,0,1,5.5,2.358,8.929,8.929,0,0,1,2.6,5.806,4.182,4.182,0,0,1,5.025,1.057,5.2,5.2,0,0,1,1.366,3.594,5.038,5.038,0,0,1-1.35,3.593,4.28,4.28,0,0,1-3.252,1.481H17.427a.422.422,0,0,1-.342-.2.692.692,0,0,1-.147-.423q0-1.788-.016-5.058T16.908,16.161Z" data-name="Icon zocial-soundcloud" id="Icon_zocial-soundcloud" style="fill: url(#linear-gradienteee);" transform="translate(383.641 458.155)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>